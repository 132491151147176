<template>
  <div id="app">
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  mounted() {}
};
</script>

<style>
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}
</style>